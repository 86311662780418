.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* 明亮模式样式 */
:root {
  --background-color: #fff;
  --text-color: #000;
}

/* 暗黑模式样式 */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #333;
    --text-color: #fff;
  }
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.video-chat-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: row;
}

@media (max-width: 768px) {
  .video-chat-container {
    flex-direction: column;
  }

  .video-container, .chat-room {
    width: 100%;
    flex: none;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */
.copy-alert {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.copy-alert.show {
  visibility: visible;
  opacity: 1;
}

/* 在您的 CSS 文件中添加 */
.chat-room {
  display: flex;
  flex-direction: column;
  height: 400px; /* 聊天室高度 */
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: scroll; /* 滚动 */
}

.chat-room-messages {
  flex-grow: 1;
  max-height: 400px;
  overflow-y: auto;
}

.chat-room-message {
  border-bottom: 1px solid #eee;
  padding: 5px;
  margin-bottom: 5px;
}

.chat-room-input {
  display: flex;
  margin-top: 10px;
}

.chat-room-input input {
  flex-grow: 1;
  padding: 5px;
  margin-right: 5px;
}

.chat-room-input button {
  width: 100px;
}

/* 自定义滚动条 */
.chat-room-messages::-webkit-scrollbar {
  width: 5px;
}

.chat-room-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-room-messages::-webkit-scrollbar-thumb {
  background: #888;
}

.chat-room-messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-room {
  display: flex;
  flex-direction: column;
  max-height: 400px; /* 設定最大高度 */
}
